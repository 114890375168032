import React from 'react'
import Footer from '../Footer/Footer';
import Menu from '../Navbar/Navbar';
import styled from 'styled-components';
export default function User() {
    return (
        <>
        <Menu/>
        <AfterLogin>
           
            <h2>Hi User!</h2>
            
        </AfterLogin>
        <Footer/>
        </>
        )
}


const AfterLogin = styled.div`
width:100%;
height:80vh;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column
`